<template>
  <b-container>
    <b-card class="mt-3">
      <app-head />
      <b-row class="mt-2">
        <b-col>
            <b>Leider ist dieser Link nicht gültig</b>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";

export default {
  name: "KeineID",
  components: {
    appHead: Head,
  },
};
</script>
